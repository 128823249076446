import styled from "styled-components";
import { ScreenSizes, Sx } from "../../../types/responsive";
import { StyledDiv } from "../StyledSx";

interface Props {
  maxWidth?: string;
  sx?: Sx
}

export const IndexContent = styled(StyledDiv)<Props>`
  padding-top: 90px;
  max-width: ${(props) => props.maxWidth || '590px'};
  @media (max-width: ${ScreenSizes.lg}) {
    padding-top: 40px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding-top: 20px;
    max-width: 100%;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 10px;
  }
`;
