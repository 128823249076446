import React from 'react';
import {ListRow} from './PricingSection';
import {Trans} from 'gatsby-plugin-react-i18next';
import {BlogLink} from '../links/Blog';
import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';

export const PlanLabelsListWrapper = styled.ul`
  padding: 0;
  position: absolute;
  top: 570px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 21%;

  li:after {
    display: block;
    content: '';
    width: 100%;
  }

  @media (max-width: ${ScreenSizes.lg}) {
    display: none;
  }
`;

export const PlanLabelsList: React.FC = () => {
  return (
    <PlanLabelsListWrapper>
      <ListRow>
        <Trans parent="span">Setup Fee</Trans>
      </ListRow>
      <ListRow sx={{height: '122.75px'}}>
        <Trans parent="span">Daily Fee</Trans>
      </ListRow>
      <ListRow sx={{height: '122.75px'}}>
        <Trans parent="span">Payment Methods</Trans>
      </ListRow>
      <ListRow sx={{height: '112.75px'}}>
        <Trans parent="span">Settlement</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Subscription engine</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">All Features + Integrations</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Unlimited API Access</Trans>
      </ListRow>
      <ListRow>
        <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
          <Trans parent="span">Fully 3D Secure</Trans>
        </BlogLink>
      </ListRow>
      <ListRow>
        <Trans parent="span">Acquirer Routing Engine</Trans>
      </ListRow>
      <ListRow sx={{height: '89.56px'}}>
        <Trans parent="span">Smart Transaction Routing & Cascading</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Payments Orchestration</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Proprietary MPI Server</Trans>
      </ListRow>
      <ListRow sx={{height: '89.56px'}}>
        <Trans parent="span">Fully PCI Level1 Compliant Card Vaulting</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Scheme tokenization + Card updater</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">3D Secure Rules (Smart Exceptions)</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Refunds</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Chargebacks</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">Dashboard Users</Trans>
      </ListRow>
      <ListRow>
        <Trans parent="span">High Priority Support (Dedicated Slack Channel)</Trans>
      </ListRow>
    </PlanLabelsListWrapper>
  );
};
